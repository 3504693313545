<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="search.name" placeholder="客户姓名" clearable size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.phone" placeholder="手机号" clearable size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.weChat" placeholder="微信号" clearable size="medium"></el-input>
        </el-col>
        <el-col :span="4">
          <el-date-picker
              style="width: 100%"
              v-model="dateSectionArray"
              type="daterange"
              range-separator="至"
              start-placeholder="下单日期"
              value-format="yyyy-MM-dd"
              end-placeholder="下单日期"
              size="medium">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="3">
          <el-select v-model="selPayeeArray" size="medium" placeholder="收款人" clearable  multiple collapse-tags>
            <el-option
                v-for="item in payeeArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="selProceedsNameArray" size="medium" placeholder="收款项目" clearable  multiple collapse-tags>
            <el-option
                v-for="item in proceedsNameArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-cascader
              :props="{multiple: true,
                      expandTrigger: 'hover',
                      label: 'name'}"
              placeholder="收款方式"
              collapse-tags
              :show-all-levels="false"
              style="width: 100%;"
              :options="paymentArray"
              v-model="selPaymentArray"
              ref="payment"
              size="medium"></el-cascader>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.orderNo" placeholder="订单号" clearable size="medium"></el-input>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" @click="queryProceedsList" size="medium">搜索</el-button>
          <el-button type="warning" @click="reset" size="medium">重置</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 15px;padding-left: 10px">
      <el-table
          border
          max-height="635"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          :data="proceedsArray"
          style="width: 99%">
        <el-table-column prop="name" label="客户名" align="center"></el-table-column>
        <el-table-column prop="orderNo" label="订单号" align="center"></el-table-column>
        <el-table-column prop="createDate" label="收款日期" sortable align="center"></el-table-column>
        <el-table-column prop="proceedsName" label="收款项目" align="center"></el-table-column>
        <el-table-column prop="orderPrice" label="订单总价" align="center"></el-table-column>
        <el-table-column prop="spareMoney" label="收款金额" align="center"></el-table-column>
        <el-table-column prop="payment" label="收款方式" align="center"></el-table-column>
        <el-table-column prop="payee" label="收款人" align="center"></el-table-column>
        <el-table-column prop="receivedAmount" label="到账金额" align="center"></el-table-column>
        <el-table-column prop="charge" label="手续费" align="center"></el-table-column>
        <el-table-column prop="receivedAccountName" label="到账账户" width="120" align="center"></el-table-column>
        <el-table-column prop="receivedDate" label="到账时间"  width="180" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注" align="center" width="150"
                         :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" width="500" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" size="mini" @click="confirmProceedsOpen(scope.row)"
                         style="line-height: 8px; padding: 4px 8px;">确认到账</el-button>
              <per-button type="primary" size="mini" @click="proceedsEditOpen(scope.row)" per="proceeds:edit"
                          style="line-height: 8px; padding: 4px 8px;">编辑</per-button>
              <per-button type="danger" size="mini" @click="deleteProceeds(scope.row)" per="proceeds:delete"
                          style="line-height: 8px; padding: 4px 8px;">删除</per-button>
              <el-button type="primary" size="mini" @click="refundInterestOpen(scope.row)"
                         style="line-height: 8px; padding: 4px 8px;">退意向金</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <proceeds-edit :proceedsEditState="true" v-if="proceedsEditState" @close="proceedsEditClose"></proceeds-edit>
    <confirm-proceeds :confirmProceedsState="true" v-if="confirmProceedsState" @close="confirmProceedsClose"></confirm-proceeds>
    <RefundInterest :refundInterestState.sync="refundInterestState" v-if="refundInterestState" @close="refundInterestClose" ></RefundInterest>
  </div>
</template>

<script>
import proceedsEdit from "@/pages/proceeds/proceeds-edit"
import confirmProceeds from "@/pages/proceeds/confirm-proceeds"
import RefundInterest from "@/pages/proceeds/refund-interest"
export default {
  name: "proceeds-list",
  components: {
    proceedsEdit,
    confirmProceeds,
    RefundInterest
  },
  provide() {
    return {
      proceeds: this.proceeds
    }
  },
  created() {
    this.pageInit()
  },
  data() {
    return {
      search: {
        name: "",
        phone: "",
        weChat: "",
        orderNo: "",
        dateSection: "",
        payee: "",
        proceedsName: "",
        payment: "",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      payeeArray: [],
      proceedsNameArray: [],
      paymentArray: [],
      dateSectionArray: [],
      selPayeeArray: [],
      selProceedsNameArray: [],
      selPaymentArray: [],
      proceedsArray: [],
      total: 0,
      page: 1,
      limit: 100,
      proceeds: {},
      proceedsEditState: false,
      confirmProceedsState: false,
      refundInterestState: false,
      loading: false
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryPayeeIds().then(response => {
        this.payeeArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryOrderProceedsProjects().then(response => {
        this.proceedsNameArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryPaymentIds().then(response => {
        this.paymentArray = JSON.parse(response.data.data);
      })
      this.queryProceedsList()
    },
    queryProceedsList() {
      if (this.dateSectionArray.length > 0) {
        this.search.dateSection = this.dateSectionArray[0]+" - "+this.dateSectionArray[1]
      }
      this.search.payee = this.selPayeeArray.toString()
      this.search.proceedsName = this.selProceedsNameArray.toString()
      this.search.payment = this.selPaymentArray.toString()
      this.$axios({
        method: "get",
        url: "/proceeds/proceedsList",
        params: {
          ...this.search,
          limit: this.limit,
          page: this.page,
          shopIds: localStorage.getItem("shops"),
        }
      }).then(response => {
        this.proceedsArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
    reset() {
      Object.keys(this.search).forEach(key=>{this.search[key]=''})
      this.dateSectionArray = []
      this.selPayeeArray = []
      this.selProceedsNameArray = []
      this.selPaymentArray = []
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryProceedsList()
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryProceedsList()
    },
    deleteProceeds(data) {
      this.$alert("确认要删除客户"+ data.name +"的这条<b>"+ data.spareMoney +"</b>元的收款吗？"
        ,'删除收款',
        {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showCancelButton: true
        }).then(()=>{
          this.$axios({
            method: "delete",
            url: "/proceeds/deleteProceeds",
            params: {
              id: data.id
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '订单收款成功' : response.data.msg,
              type: flag ? 'success' : 'error',
              duration: 1000,
            })
            if (flag) {
              this.queryProceedsList();
            }
          })
      })
    },
    proceedsEditOpen(data) {
      Object.assign(this.proceeds, data)
      this.proceedsEditState = true
    },
    proceedsEditClose() {
      this.proceedsEditState = false
      this.queryProceedsList()
    },
    confirmProceedsOpen(data) {
      //这边应该可以有更通用的处理方法。
      if (["转账入","利息"].includes(data.proceedsName)) {
        this.$alert('该收款不用确认到账', '', {
          confirmButtonText: '确定',
        });
        return false
      }

      Object.assign(this.proceeds, data)
      this.confirmProceedsState = true
    },
    confirmProceedsClose() {
      this.confirmProceedsState = false
      this.queryProceedsList()
    },

    refundInterestOpen(data) {
      if (!["意向金", "追加意向金"].includes(data.proceedsName)) {
        this.$message.error("只有意向金才可以退意向金")
      } else {
        Object.assign(this.proceeds, data)
        this.refundInterestState = true
      }
    },
    refundInterestClose() {
      this.queryProceedsList()
    },

  },
}
</script>

<style scoped>

</style>