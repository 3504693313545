<template>

  <div>
    <el-dialog
        title="退意向金"
        width="69%"
        :visible="refundInterestState"
        :modal-append-to-body="false"
        @close="close"
        center>
      <div>
        <el-form label-width="130px">
          <el-divider style="font-size: 28px;" content-position="left">
            客户 {{ proceeds.name }} 的历史收款记录
          </el-divider>

          <el-row :gutter="20">
            <el-col :span="11">
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="已收金额 " label-width="120px">
                <el-input v-model.number="proceeds.spareMoney" placeholder="已收金额"
                          :readonly="true" :disabled="true">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider content-position="left">退意向金</el-divider>

        <el-form label-width="130px" :model="form" :rules="rules" ref="form">
          <el-row>
            <el-col :span="7">
              <el-form-item label="退款日期:" prop="createDate" >
                <el-date-picker
                    style="width: 100%"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    size="medium"
                    v-model="form.createDate"
                    type="date"
                    clearable
                    placeholder="退款日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="退款人：" prop="refundPeople" >
                <el-input v-model="form.refundPeople" placeholder="请填写退款人" clearable style="width: 170px;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="退款方式：" prop="refundMethodId">
                <el-select v-model="form.refundMethodId" placeholder="请选择退款方式" style="width: 170px" clearable
                           ref="refundMethodId">
                  <el-option
                      v-for="item in refundMethodArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="退款支付账户：" prop="refundAccount" >
                <el-input v-model="form.refundAccount" placeholder="退款支付账户" clearable style="width: 170px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="8">
              <el-form-item label="退款目标账户：" prop="refundTarget" >
                <el-input v-model="form.refundTarget" placeholder="请填写退款目标账户" clearable style="width: 170px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="退款目标账号：" prop="targetAccount" >
                <el-input v-model="form.targetAccount" placeholder="请填写退款目标账号" clearable style="width: 170px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="退款金额(负数)：" prop="refundAmount" >
                <el-input v-model="form.refundAmount" type="number" placeholder="请填写退款金额" clearable style="width: 170px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="proceedsEdit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "refund-interest",
  inject: ['proceeds'],
  created() {
    this.pageInit()
  },
  props: {
    refundInterestState: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      form: {
        id: this.proceeds.id,
        createDate: "",
        refundPeople: "",
        refundMethodId: "",
        refundAccount: "",
        refundTarget: "",
        targetAccount: "",
        refundAmount: ""
      },
      rules: {
        createDate: [{required: true, message: '请选择退款日期', trigger: 'blur'}],
        refundPeople: [{required: true, message: '请选择退款人', trigger: 'blur'}],
        refundMethodId: [{required: true, message: '请选择退款方式', trigger: 'blur'}],
        refundAccount: [{required: true, message: '请选择退款支付账户', trigger: 'blur'}],
        refundTarget: [{required: true, message: '请选择退款目标账户', trigger: 'blur'}],
        targetAccount: [{required: true, message: '请选择退款目标账号', trigger: 'blur'}],
        refundAmount: [{required: true, message: '请填写退款金额', trigger: 'change'}],
      },
      tenantCrop: localStorage.getItem("tenantCrop"),
      proceedsNameArray: [],
      payeeArray: [],
      paymentArray: [],
      refundMethodArray: []
    }
  },
  methods: {
    close() {
      this.$emit("update:refundInterestState", false);
    },
    pageInit() {
      this.$selectUtils.queryRefund().then(response => {
        this.refundMethodArray = response.data.data;
      })
    },
    proceedsEdit() {
      if (!(this.form.refundAmount < 0)) {
        this.$message.error('退款金额应该为负数数字！');
        return false
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert('确认要进行退意向金申请吗？', "退意向金", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(() => {
            this.$axios({
              method: "post",
              url: "/refund/refundInterest",
              data: {
                ...this.form,
                id:this.proceeds.id,
                refundPeopleId:JSON.parse(localStorage.getItem("emp")).id,
                tenantCrop: localStorage.getItem("tenantCrop")
              },
            }).then(response => {
              if (response.data.code === 200) {
                this.$message.success("提交成功")
                let _this=this;
                setTimeout(()=>{
                  _this.$emit('close')
                  _this.close();
                },300)
              } else {
                this.$message.error(response.data.msg)
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>